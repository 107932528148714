.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}











/* Scrollbar for WebKit browsers (Chrome, Safari) */
textarea::-webkit-scrollbar {
  width: 20px;
}

textarea::-webkit-scrollbar-track {
  background: #2f2f2f;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Scrollbar for Firefox */
textarea {
  scrollbar-width: thin;
  scrollbar-color: #555 #2f2f2f;
}











/*Window Scrollbar*/

/* For Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1e1e1e; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4d4d4d; 
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #5e5e5e;
}

/* For Firefox */
html {
  scrollbar-width: thin; /* "auto" or "thin" */
 scroll-behavior: smooth;
  scrollbar-color: #4d4d4d #1e1e1e; /* thumb and track */
}

html::-webkit-scrollbar {
  width: 8px;

}

html::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

html::-webkit-scrollbar-thumb:hover {
  background: #5e5e5e;
}


